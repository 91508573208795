import React, { forwardRef, useRef, useEffect, useImperativeHandle, useCallback } from 'react';
import axios from 'axios';
import { useWebSocket } from './WebSocketProvider'; // Import the useWebSocket hook
import JSONAutocomplete from 'json-autocomplete';
import { processChunk } from './chunkManagement'; // Import the useWebSocket hook

axios.defaults.withCredentials = true;

const ScenarioManager = forwardRef((props, ref) => {
    const dialogRefCustomization = useRef(null);
    const dialogRefConfirmation = useRef(null);

    const reasonForLearningRef = useRef(null);
    const additionalNotesRef = useRef(null);
    const learnerRoleRef = useRef(null);
    const partnerRoleRef = useRef(null);
    const infoToGiveRef = useRef(null);
    const infoToRequestRef = useRef(null);
    const contextRef = useRef(null);

    useEffect(() => {
        // Initialize values from localStorage or set to default ('')
        reasonForLearningRef.current.value = localStorage.getItem('reasonForLearning') || '';
        additionalNotesRef.current.value = localStorage.getItem('additionalNotes') || '';
        learnerRoleRef.current.value = localStorage.getItem('learnerRole') || '';
        partnerRoleRef.current.value = localStorage.getItem('partnerRole') || '';
        infoToGiveRef.current.value = localStorage.getItem('infoToGive') || '';
        infoToRequestRef.current.value = localStorage.getItem('infoToRequest') || '';
        contextRef.current.value = localStorage.getItem('context') || '';
    }, []);

    // Function to handle input changes and update local storage
    const handleInputChange = (field, value) => {
        localStorage.setItem(field, value);
    };

    // Function to open the scenario manager with customization as default
    const openScenarioManagerDefault = () => {
        handlePageChange('customization'); // Set the currentPage to customization
    };

    // Define the dialog open/close functions
    const openDialog = (dialogRef) => {
        if (dialogRef.current) {
            dialogRef.current.open = true;
        }
    };

    const closeDialog = (dialogRef) => {
        if (dialogRef.current) {
            dialogRef.current.open = false;
        }
    };

    // Define the exposed method via ref to change pages
    useImperativeHandle(ref, () => ({
        openScenarioManagerDefault,
        openDialog: openDialog
    }));

    // Function to handle page changes and open/close dialogs accordingly
    const handlePageChange = (page) => {
        if (page === 'customization') {
            openDialog(dialogRefCustomization);
            closeDialog(dialogRefConfirmation);
        } else if (page === 'confirmation') {
            openDialog(dialogRefConfirmation);
            closeDialog(dialogRefCustomization);
        }
    };

    const createScenario = async () => {
        const scenarioData = {
            reasonForLearning: reasonForLearningRef.current.value,
            additionalNotes: additionalNotesRef.current.value,
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/create-scenario`, scenarioData, {
                withCredentials: true
            });
            const responseData = JSON.parse(response.data);
            updateFieldWithResponse(learnerRoleRef, responseData["1"], 'learnerRole'); // Access by key "1"
            updateFieldWithResponse(partnerRoleRef, responseData["2"], 'partnerRole'); // Access by key "2"
            updateFieldWithResponse(infoToGiveRef, responseData["3"], 'infoToGive'); // Access by key "3" for Information to Give
            updateFieldWithResponse(infoToRequestRef, responseData["4"], 'infoToRequest'); // Access by key "4" for Information to Request
            updateFieldWithResponse(contextRef, responseData["5"], 'context'); // Access by key "5"
        } catch (error) {
            console.error('Error creating the scenario:', error.response ? error.response.data : error.message);
        }
        handlePageChange('confirmation');
    };

    const updateFieldWithResponse = useCallback((ref, value, localStorageKey) => {
        if (ref.current) {
            ref.current.value = value;
            localStorage.setItem(localStorageKey, value);
        }
    }, []); // Add dependencies for useCallback if there are any


    const handleNext = async () => {
        handlePageChange('confirmation');
        // Check if all fields are null or blank
        if (
            (!learnerRoleRef.current.value || learnerRoleRef.current.value.trim() === '') &&
            (!partnerRoleRef.current.value || partnerRoleRef.current.value.trim() === '') &&
            (!infoToGiveRef.current.value || infoToGiveRef.current.value.trim() === '') &&
            (!infoToRequestRef.current.value || infoToRequestRef.current.value.trim() === '') &&
            (!contextRef.current.value || contextRef.current.value.trim() === '')
        ) {
            createScenario();
        }
    };

    const handleBack = () => {
        handlePageChange('customization');
    };

    const handleConfirm = async () => {
        closeDialog(dialogRefConfirmation);
        const scenarioData = {
            languageLearnerRole: learnerRoleRef.current.value,
            conversationPartnerRole: partnerRoleRef.current.value,
            informationToGive: infoToGiveRef.current.value,
            informationToRequest: infoToRequestRef.current.value,
            context: contextRef.current.value
        };
        try {
            // Send a POST request to the server
            await axios.post(`${process.env.REACT_APP_API_URL}/api/edit-scenario`, scenarioData, {
                withCredentials: true
            });
        } catch (error) {
            console.error('Error updating the scenario:', error.response ? error.response.data : error.message);
        }
    };

    // Auto resize the textarea based on its content, now accepts a ref as a parameter
    const autoResizeTextarea = (ref) => {
        const messageField = ref.current;
        if (!messageField) {
            //console.error('Custom element not found');
            return;
        }
        // Access the shadow root of the custom element
        const shadowRoot = messageField.shadowRoot;
        if (!shadowRoot) {
            //console.error('Shadow root not accessible or does not exist');
            return;
        }
        // Find the input element within the shadow root
        const inputElement = shadowRoot.querySelector('.input');
        if (!inputElement) {
            //console.error('Input element not found');
            return;
        }
        // Temporarily disable scrollbars
        inputElement.style.overflow = 'hidden';
        // Reset the height to auto to correctly calculate the scrollHeight
        inputElement.style.height = 'auto';
        // Calculate the desired height, but do not exceed 96px
        let desiredHeight = inputElement.scrollHeight;
        if (desiredHeight < 24) {
            desiredHeight = 24; // Ensure minimum height of 24px
        } else if (desiredHeight > 96) {
            desiredHeight = 96; // Restrict height to a maximum of 96px
        }
        // Apply the calculated height
        inputElement.style.height = `${desiredHeight}px`;
        // Restore overflow property if necessary
        inputElement.style.overflow = '';
    };

    return (
        <>
            <md-dialog ref={dialogRefCustomization} className="ScenarioCustomization">
                <CustomizeScenarioComponent
                    handleInputChange={handleInputChange}
                    handleNext={handleNext}
                    autoResizeTextarea={autoResizeTextarea}
                    reasonForLearningRef={reasonForLearningRef}
                    additionalNotesRef={additionalNotesRef}
                />
            </md-dialog>

            <md-dialog ref={dialogRefConfirmation} className="ScenarioConfirmation">
                <ConfirmScenarioComponent
                    handleInputChange={handleInputChange}
                    updateFieldWithResponse={updateFieldWithResponse}
                    handleBack={handleBack}
                    handleConfirm={handleConfirm}
                    createScenario={createScenario}
                    autoResizeTextarea={autoResizeTextarea}
                    learnerRoleRef={learnerRoleRef}
                    partnerRoleRef={partnerRoleRef}
                    infoToGiveRef={infoToGiveRef}
                    infoToRequestRef={infoToRequestRef}
                    contextRef={contextRef}
                />
            </md-dialog>
        </>
    );

});

const CustomizeScenarioComponent = ({
    handleInputChange,
    handleNext,
    autoResizeTextarea,
    reasonForLearningRef,
    additionalNotesRef,
}) => {
    const resetCustomization = async () => {
        if (reasonForLearningRef.current) {
            reasonForLearningRef.current.value = '';
            localStorage.removeItem('reasonForLearning');
        }
        if (additionalNotesRef.current) {
            additionalNotesRef.current.value = '';
            localStorage.removeItem('additionalNotes');
        }
    };

    return (
        <>
            <span slot="headline">
                <md-icon-button form="scenarioForm" value="close" aria-label="Close dialog">
                    <md-icon>close</md-icon>
                </md-icon-button>
                <span className="headline">Customize Your Scenario</span>
            </span>
            <form id="scenarioForm" slot="content" method="dialog" className="scenario-content">
                <div className="scenario-row scenario-manager">
                    <md-filled-text-field
                        ref={reasonForLearningRef} // Use the ref directly here
                        required                        
                        onInput={(e) => {
                            autoResizeTextarea(reasonForLearningRef); // Pass the ref here
                            handleInputChange('reasonForLearning', e.target.value);
                        }}
                        editable
                        label="Reason for learning"
                        placeholder="e.g., Business, Travel, Hobby"
                    />
                </div>
                <div className="scenario-row scenario-manager">
                    <md-filled-text-field
                        type='textarea'
                        rows="3"
                        ref={additionalNotesRef} // Use the ref directly here
                        onInput={(e) => {
                            autoResizeTextarea(additionalNotesRef);
                            handleInputChange('additionalNotes', e.target.value);
                        }}
                        editable
                        label="Additional notes"
                        placeholder="e.g., Preferred topics, etc."
                    />
                </div>
            </form>
            <div slot="actions">
                <md-text-button onClick={resetCustomization}>Reset</md-text-button>
                <md-filled-button onClick={handleNext}>Next</md-filled-button>
            </div>
        </>
    );
};
const ConfirmScenarioComponent = ({
    handleInputChange,
    updateFieldWithResponse,
    handleBack,
    handleConfirm,
    createScenario,
    autoResizeTextarea,
    learnerRoleRef,
    partnerRoleRef,
    infoToGiveRef,
    infoToRequestRef,
    contextRef,
}) => {
    const ws = useWebSocket();
    useEffect(() => {
        if (!ws) return;
        const onMessage = async (event) => {
            const data = JSON.parse(event.data);
            if (data.key && data.chunkId !== undefined) {
                if (data.key === "scenarioChunks") {
                    //console.log("chunkid: ", data.chunkId, " chunk: ", data.chunk);
                    const temporaryChunkStorage = await processChunk(data); // Await the async function
                    if (temporaryChunkStorage) {
                        updateScenarioFields(data, temporaryChunkStorage);
                    }
                }
            }
        };
        function updateScenarioFields(data, temporaryChunkStorage) {
            //console.log("temporaryChunkStorage: ", JSON.parse(JSON.stringify(temporaryChunkStorage)));
            if (!temporaryChunkStorage || !temporaryChunkStorage.chunks) {
                console.error(`No temporaryChunkStorage found for scenario`);
                return;
            }
            // Sort chunkIds numerically to ensure correct order
            const sortedChunkIds = Object.keys(temporaryChunkStorage.chunks).map(Number).sort((a, b) => a - b);
            // Combine chunks based on sorted order
            const combinedData = sortedChunkIds.map(chunkId => temporaryChunkStorage.chunks[chunkId].chunk).join('');
            //console.log("temporaryChunkStorage: ", JSON.parse(JSON.stringify(temporaryChunkStorage)));
            try {
                const completeJsonString = JSONAutocomplete(combinedData); // Assuming JSONAutocomplete is defined elsewhere
                const scenarioDetails = JSON.parse(completeJsonString);
                // Directly update scenario, ensuring that if no value is present, "" is used instead
                updateFieldWithResponse(learnerRoleRef, scenarioDetails["1"] ?? "", 'learnerRole'); // Access by key "1", default to "" if undefined
                updateFieldWithResponse(partnerRoleRef, scenarioDetails["2"] ?? "", 'partnerRole'); // Access by key "2", default to "" if undefined
                updateFieldWithResponse(infoToGiveRef, scenarioDetails["3"] ?? "", 'infoToGive'); // Access by key "3", split goal into "Information to Give"
                updateFieldWithResponse(infoToRequestRef, scenarioDetails["4"] ?? "", 'infoToRequest'); // Access by key "4", split goal into "Information to Request"
                updateFieldWithResponse(contextRef, scenarioDetails["5"] ?? "", 'context'); // Access by key "5", adjust for added fields
            } catch (error) {
                // Log or handle error as needed
                //console.error("Error processing scenario details:", error);
            }


        }
        ws.addEventListener('message', onMessage);
        return () => {
            ws.removeEventListener('message', onMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws, updateFieldWithResponse]); 



    const resetConfirmation = async () => {
        if (learnerRoleRef.current) {
            learnerRoleRef.current.value = '';
            localStorage.removeItem('learnerRole');
        }

        if (partnerRoleRef.current) {
            partnerRoleRef.current.value = '';
            localStorage.removeItem('partnerRole');
        }

        if (infoToGiveRef.current) {
            infoToGiveRef.current.value = '';
            localStorage.removeItem('informationToGive');
        }

        if (infoToRequestRef.current) {
            infoToRequestRef.current.value = '';
            localStorage.removeItem('informationToRequest');
        }

        if (contextRef.current) {
            contextRef.current.value = '';
            localStorage.removeItem('context');
        }
    };

    return (
        <>
            <span slot="headline">
                <md-icon-button form="confirmationForm" value="close" aria-label="Close dialog">
                    <md-icon>close</md-icon>
                </md-icon-button>
                <span className="headline">Review Your Scenario</span>
            </span>
            <form id="confirmationForm" slot="content" method="dialog" className="confirmation-content">
                <div className="confirmation-row scenario-manager">
                    <md-filled-text-field
                        label='Learner Role'
                        placeholder='Enter your role in the scenario'
                        ref={learnerRoleRef} // Directly use the ref here
                        required                        
                        onInput={(e) => {
                            autoResizeTextarea(learnerRoleRef);
                            handleInputChange('learnerRole', e.target.value);
                        }}
                        editable
                    />
                </div>
                <div className="confirmation-row scenario-manager">
                    <md-filled-text-field
                        label='Partner Role'
                        placeholder="Enter your partner's role in the scenario"
                        ref={partnerRoleRef} // Directly use the ref here
                        required
                        onInput={(e) => {
                            autoResizeTextarea(partnerRoleRef);
                            handleInputChange('partnerRole', e.target.value);
                        }}
                        editable
                    />
                </div>
                <div className="confirmation-row scenario-manager">
                    <md-filled-text-field
                        label='Information to Give'
                        placeholder='Specify the information you will provide in the conversation'
                        type='textarea'
                        rows="2"
                        ref={infoToGiveRef} // Directly use the ref here
                        required
                        onInput={(e) => {
                            autoResizeTextarea(infoToGiveRef);
                            handleInputChange('infoToGive', e.target.value);
                        }}
                        editable
                    />
                </div>
                <div className="confirmation-row scenario-manager">
                    <md-filled-text-field
                        label='Information to Request'
                        placeholder='Define the information you will ask for from your partner'
                        type='textarea'
                        rows="2"
                        ref={infoToRequestRef} // Directly use the ref here
                        required
                        onInput={(e) => {
                            autoResizeTextarea(infoToRequestRef);
                            handleInputChange('infoToRequest', e.target.value);
                        }}
                        editable
                    />
                </div>
                <div className="confirmation-row scenario-manager">
                    <md-filled-text-field
                        label='Conversation Context'
                        placeholder='Provide context for the conversation'
                        type='textarea'
                        rows="3"
                        ref={contextRef} // Directly use the ref here
                        required
                        onInput={(e) => {
                            autoResizeTextarea(contextRef);
                            handleInputChange('context', e.target.value);
                        }}
                        editable
                    />
                </div>
                <div style={{ padding: '10px' }}>
                    <md-outlined-button onClick={(e) => {
                        e.preventDefault(); // Prevents the default form submission action
                        e.stopPropagation(); // Stops the click event from propagating to parent elements
                        createScenario();
                    }}>Generate New Scenario</md-outlined-button>
                </div>
            </form>
            <div slot="actions">
                <md-text-button onClick={resetConfirmation}>Reset</md-text-button>
                <md-text-button onClick={handleBack}>Back</md-text-button>
                <md-filled-button onClick={() => {
                    handleConfirm();
                }}>
                    Confirm
                </md-filled-button>
            </div>
        </>
    );
};

export default ScenarioManager;
