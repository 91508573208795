let temporaryChunkStorage = {}; // Initialize the storage object

export async function processChunk(data) {
    // Check for reset signal
    if (data.chunkId === -1) {
        resetTemporaryStorageForTarget(data); // Adjust to pass object with key and subkey
        return null;
    }
    // Initialize storage for new key if it doesn't exist
    if (!temporaryChunkStorage[data.key]) {
        //console.log("temp key missing");
        temporaryChunkStorage[data.key] = {};
    }
    // Initialize subkey storage if it doesn't exist
    if (!temporaryChunkStorage[data.key][data.subkey]) {
        //console.log("temp subkey missing");
        temporaryChunkStorage[data.key][data.subkey] = {
            lastChunkId: 0,
            chunks: {},
        };
    }

    const highestMissingChunkId = findHighestMissingChunkId(data.key, data.subkey, data.chunkId);
    storeChunk({ data });
    if (highestMissingChunkId !== undefined) {
        //console.log("missing chunk: ", highestMissingChunkId);
        await requestChunksFromServer(data.key, data.subkey, highestMissingChunkId);
    }
    try {
        return temporaryChunkStorage[data.key][data.subkey];
    } catch {
        return null;
    }
}

function resetTemporaryStorageForTarget(data) {
    const key = data.key;
    const subkey = data.subkey;
    // Check if the key and subkey exist in the temporaryChunkStorage
    if (temporaryChunkStorage[key] && temporaryChunkStorage[key][subkey]) {
        //console.log("storage reset for subkey");
        delete temporaryChunkStorage[key][subkey];
    }
    // Check if the key layer is empty after deletion and remove it if so
    if (temporaryChunkStorage[key] && Object.keys(temporaryChunkStorage[key]).length === 0) {
        //console.log("storage reset key");
        delete temporaryChunkStorage[key];
    }
}

function findHighestMissingChunkId(key, subkey, chunkId) {
    // Access the specified storage location directly using key and subkey
    const storage = temporaryChunkStorage[key] && temporaryChunkStorage[key][subkey];
    if (!storage) {
        // If no storage exists for the key and subkey, assume the highest missing chunk ID is just before the current
        return chunkId - 1;
    }

    // Retrieve the last successfully stored chunk ID for comparison
    const lastChunkId = storage.lastChunkId;
    let expectedChunkId = chunkId - 1; // The ID we would expect if no chunks were missing

    // If the expected chunk ID does not match the last stored chunk ID, it indicates a missing chunk
    if (expectedChunkId !== lastChunkId) {
        return chunkId - 1; // Return the chunk ID just before the current as the highest missing
    }

    // If the expected and last chunk IDs match, there are no missing chunks up to this point
    return undefined;
}

function storeChunk({
    data,
    wasRequested = false,
}) {
    // Extract key and subkey from data, providing a default value for subkey if it's not present
    const { key, subkey = 'default' } = data;
    // Initialize key and subkey layer if it doesn't exist
    if (!temporaryChunkStorage[key]) {
        temporaryChunkStorage[key] = {};
    }
    if (!temporaryChunkStorage[key][subkey]) {
        temporaryChunkStorage[key][subkey] = {
            lastChunkId: 0,
            chunks: {},
        };
    }

    const { chunkId } = data;
    if (!temporaryChunkStorage[key][subkey].chunks[chunkId]) {
        temporaryChunkStorage[key][subkey].chunks[chunkId] = data;
        if (!wasRequested) {
            temporaryChunkStorage[key][subkey].lastChunkId = chunkId;
        }
    }
}

async function requestChunksFromServer(key, subkey, highestMissingChunkId) {
    try {       
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/request-chunks`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                key, 
                subkey, 
                highestMissingChunkId,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const missingChunks = await response.json();
        missingChunks.forEach(chunkData => {
            // Adjust the storeChunk call to fit the new system
            // Ensure that 'storeChunk' function is updated to accept the object format accordingly
            storeChunk({
                data: chunkData,
                wasRequested: true,
                key, // Directly use 'key' instead of extracting from chunkData
                subkey // Directly use 'subkey' instead of extracting from chunkData
            });
        });
    } catch (error) {
        console.error('Error requesting chunks:', error);
    }
}
