import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useWebSocket } from './WebSocketProvider'; // Import the useWebSocket hook
import JSONAutocomplete from 'json-autocomplete';
import { processChunk } from './chunkManagement'; // Import the useWebSocket hook

function ConversationArea({
	showTransliteration,
	language,
}) {
	const [activeMessageId, setActiveMessageId] = useState(null);
	const [conversationHistory, setConversationHistory] = useState([]);

	const ws = useWebSocket();
	useEffect(() => {
		if (!ws) return;

		const onMessage = (event) => {
			const data = JSON.parse(event.data);

			if (data.conversationHistory) {
				setConversationHistory(data.conversationHistory);
			}
		};

		ws.addEventListener('message', onMessage);

		return () => {
			ws.removeEventListener('message', onMessage);
		};
	}, [ws, setConversationHistory]); // Add other dependencies as necessary

	useEffect(() => {
		const handleClickOutside = (event) => {
			const optionsContainer = document.querySelector('.message-options-container');
			// Check if the clicked element or its parent is a .message element
			const isMessageClicked = event.target.closest('.message');

			if (!optionsContainer) return;

			// If the clicked element is not within optionsContainer and not a .message, deactivate
			if (!optionsContainer.contains(event.target) && !isMessageClicked) {
				setActiveMessageId(null);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		// Must be mousedown. Checking click causes it to close prematurely
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []); // Ensure dependencies are correctly set if any

	const handleFullSentenceTranslation = async () => {
		if (!activeMessageId) return;
		setActiveMessageId(null); // Close the menu

		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/api/translate-sentence`, {
				messageId: activeMessageId,
				language,
			});
		} catch (error) {
			console.error('Error translating sentence:', error);
		}
	};

	const renderMessageOptions = () => {
		return (
			<div className="message-options-container">
				<md-outlined-button
					className="button"
					onClick={handleFullSentenceTranslation}
					title="Translate Message"
					aria-label="Translate Message">
					<md-icon style={{ transform: 'translateY(11.5%)', fontSize: '1rem' }}>translate</md-icon>
					Full Sentence Translation
				</md-outlined-button>
				{/*<md-filled-icon-button
					className="button"
					onClick={handleTransliteration}
					title="Transliterate Message"
					aria-label="Transliterate Message">
					<md-icon>language_japanese_kana</md-icon>
				</md-filled-icon-button>*/}
			</div>
		);
	};

	return (
		<div className="conversation-area">
{/*			<button onClick={() => console.log("conversationHistory: ", conversationHistory)}>Log Conversation History</button>
*/}			<div className="conversation-flex-container">
				{conversationHistory.map((message) => (
					<MessageContainer
						key={message.id}
						id={message.id}
						sender={message.sender}
						message={message.message}
						originalMessage={message.originalMessage}
						morphemeTranslationDetails={message.morphemeTranslationDetails}
						fullSentenceTranslation={message.fullSentenceTranslation}
						showTransliteration={showTransliteration}
						setActiveMessageId={setActiveMessageId}
					/>
				))}
			</div>
			<span className="animationSupportContainer">
				{activeMessageId !== null && renderMessageOptions()}
			</span>
		</div>

	);
}
function MessageContainer({
	id,
	sender,
	message,
	originalMessage,
	morphemeTranslationDetails,
	fullSentenceTranslation,
	showTransliteration,
	setActiveMessageId,
}) {
	const [showMorphemeTranslationDetails, setShowMorphemeTranslationDetails] = useState(true);

	const toggleMorphemeTranslationDetails = () => {
		setShowMorphemeTranslationDetails(prev => !prev);
	};
	let pressTimer;
	let isLongPress = false;
	let isClickEvent = false;
	let isScrolling = false;

	const handleDesktopPressStart = (event) => {
		event.stopPropagation(); // Prevent event from bubbling up
		isClickEvent = true;
		pressTimer = setTimeout(() => {
			isLongPress = true;
			setActiveMessageId(id); // Set active message on long press
		}, 500); // Time in milliseconds for long press
	};

	const handleDesktopPressEnd = (event) => {
		event.stopPropagation(); // Prevent event from bubbling up
		clearTimeout(pressTimer);
		if (!isLongPress && isClickEvent) {
			toggleMorphemeTranslationDetails(id);
		}
		isClickEvent = false; // Reset click event flag
	};

	const handleMobilePressStart = (event) => {
		event.stopPropagation(); // Prevent event from bubbling up
		isLongPress = false;
		isScrolling = false;
		pressTimer = setTimeout(() => {
			isLongPress = true;
			setActiveMessageId(id);
		}, 500);
	};

	const handleMobileTouchMove = (event) => {
		isScrolling = true;
	};

	const handleMobilePressEnd = (event) => {
		event.stopPropagation(); // Prevent event from bubbling up
		event.preventDefault(); // Prevent default to improve mobile interaction
		clearTimeout(pressTimer);
		if (!isLongPress && !isScrolling) {
			toggleMorphemeTranslationDetails(id);
		}
	};
	return (
		<div
			className={`message ${sender}`} // Adjust `sender` based on your actual state or props
			onMouseDown={handleDesktopPressStart}
			onMouseUp={handleDesktopPressEnd}
			onTouchStart={handleMobilePressStart}
			onTouchMove={handleMobileTouchMove}
			onTouchEnd={handleMobilePressEnd}
		>
			<MessageComponent
				key={id}
				id={id}
				sender={sender}
				message={message}
				originalMessage={originalMessage}
				morphemeTranslationDetails={morphemeTranslationDetails}
				fullSentenceTranslation={fullSentenceTranslation}
				showTransliteration={showTransliteration}
				showMorphemeTranslationDetails={showMorphemeTranslationDetails}
			/>
		</div>
	)
}

function MessageComponent({
	id,
	sender,
	message: initialMessage, // Rename to avoid confusion with state variable
	originalMessage: initialOriginalMessage,
	morphemeTranslationDetails: initialMorphemeTranslationDetails,
	fullSentenceTranslation: initialFullSentenceTranslation,
	showTransliteration,
	showMorphemeTranslationDetails
}) {
	const [message, setMessage] = useState(initialMessage);
	const [originalMessage, setOriginalMessage] = useState(initialOriginalMessage);
	const [morphemeTranslationDetails, setMorphemeTranslationDetails] = useState(initialMorphemeTranslationDetails);
	const [fullSentenceTranslation, setFullSentenceTranslation] = useState(initialFullSentenceTranslation);

	useEffect(() => {
		setMessage(initialMessage);
	}, [initialMessage]);

	useEffect(() => {
		setOriginalMessage(initialOriginalMessage);
	}, [initialOriginalMessage]);

	useEffect(() => {
		setMorphemeTranslationDetails(initialMorphemeTranslationDetails);
	}, [initialMorphemeTranslationDetails]);

	useEffect(() => {
		setFullSentenceTranslation(initialFullSentenceTranslation);
	}, [initialFullSentenceTranslation]);

	const ws = useWebSocket();
	// Assuming temporaryChunkStorage and state setters are defined in the component scope
	useEffect(() => {
		if (!ws) return;
		const onMessage = async (event) => {
			const data = JSON.parse(event.data);
			// Assume the key and subkey are now part of the incoming data structure
			// and that id corresponds to the key we want to match against.
			if (data.key && data.chunkId !== undefined && data.subkey) {
				if (data.key === id) { // Check if the key matches the id
					//console.log("chunkid: ", data.chunkId, " chunk: ", data.chunk);
					const temporaryChunkStorage = await processChunk(data); // Await the async function, adjust processChunk to accept an object
					if (temporaryChunkStorage) {
						updateMessageInHistory({
							chunkData: data,
							temporaryChunkStorage,
						}); // Adjust updateMessageInHistory to accept an object
					}
				}
			}
		};
		function updateMessageInHistory({
			chunkData,
			temporaryChunkStorage,
		}) {
			const messageId = chunkData.key;
			const target = chunkData.subkey;

			//console.log("temporaryChunkStorage: ", JSON.parse(JSON.stringify(temporaryChunkStorage)));
			if (!temporaryChunkStorage && temporaryChunkStorage.chunks) {
				console.error(`No temporaryChunkStorage found for key: ${messageId}, subkey: ${target}`);
				return;
			}
			// Sort chunkIds numerically to ensure correct order
			const sortedChunkIds = Object.keys(temporaryChunkStorage.chunks).map(Number).sort((a, b) => a - b);
			// Combine chunks based on sorted order
			const combinedData = sortedChunkIds.map(chunkId => temporaryChunkStorage.chunks[chunkId].chunk).join('');
			//console.log("temporaryChunkStorage: ", JSON.parse(JSON.stringify(temporaryChunkStorage)));
			switch (target) {
				case 'message':
					const isSystemMessage = sender === 'system';
					if (isSystemMessage) {
						// For system messages, replace the beginning of the original message with combinedData
						let currentMessage = message;
						const combinedDataLength = combinedData.length;
						// Replace the beginning of the original message with the combinedData
						currentMessage = combinedData + currentMessage.substring(combinedDataLength);
						setMessage(currentMessage);
					} else {
						// For non-system messages, it seems like the entire message is replaced with combinedData
						setMessage(combinedData);
					}
					break;
				case 'originalMessage':
					// Directly update the original message state, assuming such updates are required
					setOriginalMessage(combinedData);
					break;
				case 'morphemeTranslationDetails':
					try {
						const completeJsonString = JSONAutocomplete(combinedData); // Assuming JSONAutocomplete is defined elsewhere
						const translations = JSON.parse(completeJsonString);

						// Directly update morphemeTranslationDetails state
						setMorphemeTranslationDetails(translations);
					} catch (error) {
						//console.log("error: ", error);
					}
					break;
				case 'fullSentenceTranslation':
					// Directly update the full sentence translation
					setFullSentenceTranslation(combinedData);
					break;
				// Add more cases as needed for different targets
				default:
					// Your default case code here
					console.warn(`Unrecognized target: ${target}`);
					// You could handle unexpected targets or perform a generic operation here
					break;
			}
		}
		ws.addEventListener('message', onMessage);
		return () => {
			ws.removeEventListener('message', onMessage);
		};
	}, [ws, id, message, sender]); // Removed state setters from the dependency array as they are stable

	const renderMorphemes = (sender, morphemeDetails, showTransliteration) => {
		let translations = {};
		try {
			translations = typeof morphemeDetails === 'string' ? JSON.parse(morphemeDetails) : morphemeDetails;
			if (!translations || typeof translations !== 'object') {
				translations = {};
			} else {
				// Convert new format back to the old format
				const convertedTranslations = {};
				Object.entries(translations).forEach(([key, value]) => {
					if (Array.isArray(value) && value.length === 3) {
						convertedTranslations[key] = {
							word: value[0],
							translation: value[1],
							transliteration: value[2]
						};
					}
				});
				translations = convertedTranslations;
			}
		} catch (error) {
			//console.log("Error parsing morphemeDetails: ", error);
			//console.log("morphemeDetails: ", morphemeDetails);
			translations = {}; // Ensure translations is always an object, even in case of an error
		}

		return (
			<div className={`translation-grid ${sender} shared-message-content`}>
				{Object.entries(translations).map(([key, value], index) => {
					// The object check is technically not necessary anymore since we've converted everything,
					// but it's good practice to keep it for safety.
					if (!value || typeof value !== 'object') {
						return null;
					}

					const word = value.word ?? "";
					const translation = value.translation ?? "";
					const transliteration = value.transliteration ?? "";

					const morphemeKey = `morpheme-${key}-${index}`;

					return (
						<div key={morphemeKey} className="translation-pair">
							<span className={`original-word`}>{word}</span>
							{showTransliteration && <span className="transliteration-word">{transliteration}</span>}
							<span className="translated-word">{translation}</span>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<span>
			{originalMessage && (
				<div className="original-message shared-message-content">
					<span>{originalMessage}</span>
				</div>
			)}

			<div className="message-container">
				{showMorphemeTranslationDetails && morphemeTranslationDetails ? (
					<div className={`shared-message-content`}>
						{/* Assuming `renderMorphemes` is a function that takes `morphemeTranslationDetails` */}
						{renderMorphemes(sender, morphemeTranslationDetails, showTransliteration)}
					</div>
				) : (
					<div className="message-text shared-message-content">{message}</div>
				)}
			</div>

			{fullSentenceTranslation && (
				<div className="full-sentence-translation shared-message-content">
					{fullSentenceTranslation}
				</div>
			)}
		</span>
	);
}

export default ConversationArea;
