/*TODO
add a explain grammar button
add a scenario manager with roles for the user and assistant
convert to typescript?
add buttons to get a new response or edit your message
fix cut off responses for user and assistant
Aim for 5 minute~ conversations
add a feature to detect when the conversation has concluded based on the user's goal
provide a short grammar and cultural nuance report at the end of the conversation. Can be split into two
Show the user how much they've improved in that short conversation with vocab or grammar
Switch from splitting words apart, to splitting morphemes apart
Split root words/morphemes like "cat" or "run" apart from grammatical morphemes like "-ed" and "-s"
Add content moderation (it's free) https://platform.openai.com/docs/guides/moderation
Highlight a word that's used X times, then fade out from there
For displaying grammatical syntax, create a filter for syntactic structures, allowing the user to see the original message, and the message without the modifiers that complicate the sentence.
Create a visual network of words encountered, and connect them to other words in the same sentence, or by proximity to the word. For example, the connection strength between re- and learn will be calculated by frequency seen together and distance from each other
Maybe I could have free non-ai users that can use the app with paying users. This creates a network of penpals for paying users to choose from, while encouraging paying users. The paying users can cover the free users.

Must fix morpheme detail information rendering quotations incorrectly. Should sanitize them.
*/
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from 'axios';

import ScenarioManager from './components/ScenarioManager';
import ConversationArea from './components/ConversationArea';
import { useWebSocket } from './components/WebSocketProvider'; // Import the useWebSocket hook
import { WebSocketProvider } from './components/WebSocketProvider'; // Path to your WebSocketProvider

axios.defaults.withCredentials = true;

function App() {
	const [language, setLanguage] = useState(localStorage.getItem('language') || 'French');
	const [showTransliteration, setShowTransliteration] = useState(localStorage.getItem('showTransliteration') || false);
	const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

	const messageFieldRef = useRef(null);
	const scenarioMenuItemRef = useRef(null);
	const languageMenuItemRef = useRef(null);
	const dialogRefLanguage = useRef(null);
	const conversationOptionsButtonRef = useRef(null);

	useEffect(() => {
		import(`./themes/${theme}.css`)
			.then(() => {
			})
			.catch(err => {
				console.error(`Failed to load ${theme} theme`, err);
			});
		document.body.className = theme;
	}, [theme]); // Re-run effect when theme changes

	const handleResetConversation = async () => {
		const messageField = messageFieldRef.current;
		messageField.value = "";
		localStorage.removeItem('myInputValue');
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-conversation`);
		} catch (error) {
			console.error('Error resetting conversation:', error);
		}
	};

	function MessageInput() {
		// Update local storage whenever the input value changes

		const handleInputChange = (event) => {
			const newValue = event.target.value;
			localStorage.setItem('myInputValue', newValue);
		};

		useEffect(() => {
			const messageField = messageFieldRef.current;
			messageField.value = (localStorage.getItem('myInputValue') || '');
			autoResizeTextarea();
		}, []);

		// Auto resize the textarea based on its content
		const autoResizeTextarea = () => {
			const messageField = messageFieldRef.current;
			if (!messageField) {
				//console.error('Custom element not found');
				return;
			}
			// Access the shadow root of the custom element
			const shadowRoot = messageField.shadowRoot;
			if (!shadowRoot) {
				//console.error('Shadow root not accessible or does not exist');
				return;
			}
			// Find the input element within the shadow root
			const inputElement = shadowRoot.querySelector('.input');
			if (!inputElement) {
				//console.error('Input element not found');
				return;
			}
			// Temporarily disable scrollbars
			inputElement.style.overflow = 'hidden';
			// Reset the height to auto to correctly calculate the scrollHeight
			inputElement.style.height = 'auto';
			// Calculate the desired height, but do not exceed 96px
			let desiredHeight = inputElement.scrollHeight;
			if (desiredHeight < 24) {
				desiredHeight = 24; // Ensure minimum height of 24px
			} else if (desiredHeight > 96) {
				desiredHeight = 96; // Restrict height to a fimum of 96px
			}
			// Apply the calculated height
			inputElement.style.height = `${desiredHeight}px`;
			// Restore overflow property if necessary
			inputElement.style.overflow = '';
		};

		// Submit handler for the message form
		const handleSubmit = async (e) => {
			e.preventDefault();
			const messageField = messageFieldRef.current;
			const message = messageField.value.trim();
			if (!message) return;
			messageField.value = '';
			localStorage.removeItem('myInputValue');
			try {
				//console.log(process.env.REACT_APP_API_URL);
				await axios.post(`${process.env.REACT_APP_API_URL}/api/send-message`, {
					message,
					language,
					showTransliteration
				}, {
					withCredentials: true
				});
			} catch (error) {
				console.error('Error sending message:', error);
			}
		};

		return (
			<form className="message-form" onSubmit={handleSubmit}>
				<md-outlined-text-field
					id="userInput"
					type="textarea"
					ref={messageFieldRef}
					rows="1"
					placeholder="Type your mixed language message..."
					onInput={(e) => {
						autoResizeTextarea();
						handleInputChange(e);
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter' && !e.shiftKey) {
							handleSubmit(e);
							e.preventDefault();
							autoResizeTextarea();
						}
					}}
				/>
				<md-icon-button type="submit" className="send-button">
					<md-icon filled>send</md-icon>
				</md-icon-button>
			</form>
		);
	}
	const LanguageManager = ({ handleLanguageChange }) => {
		const languageInputRef = useRef(null);
		const handleSubmit = (e) => {
			handleLanguageChange(languageInputRef.current.value);
			localStorage.setItem('language', languageInputRef.current.value);
			dialogRefLanguage.current.open = false;
		};
		return (
			<md-dialog ref={dialogRefLanguage}>
				<span slot="headline">
					<md-icon-button form="languageForm" value="close" aria-label="Close dialog">
						<md-icon>close</md-icon>
					</md-icon-button>
					<span className="headline">Select Target Language</span>
				</span>
				<form id="languageForm" className="language-form" slot="content" method="dialog" onSubmit={handleSubmit}>
					<md-filled-text-field
						label="Choose Your Language"
						id="languageInput"
						ref={languageInputRef}
						value={language}
						placeholder="e.g., Persian, Traditional Chinese, etc."
						required
						editable
					/>
				</form>
				<div slot="actions">
					<md-text-button form="languageForm" value="cancel">Cancel</md-text-button>
					<md-filled-button onClick={() => {
						handleSubmit();
					}}>
						Confirm
					</md-filled-button>
				</div>
			</md-dialog>
		);
	}

	const ConversationOptionsMenu = ({ language, showTransliteration, theme, handleResetConversation }) => {				
		const scenarioManagerRef = useRef(null);
		const languageMenuRef = useRef(null);
		const languageManagerRef = useRef(null);
		const [hasOpenedScenarioManager, setHasOpenedScenarioManager] = useState(false);

		const openScenarioManager = () => {
			scenarioManagerRef.current.openScenarioManagerDefault();
			setHasOpenedScenarioManager(true); // Set the state to true when the Scenario Manager is opened
			if (conversationOptionsButtonRef.current) {
				conversationOptionsButtonRef.current.classList.remove('pulse', 'pulse-round');
			}
			if (scenarioMenuItemRef.current) {
				scenarioMenuItemRef.current.classList.remove('pulse');
			}
		};
		const openLanguageManager = () => {
			dialogRefLanguage.current.open = true;
		};

		// Tutorial highlight
		useEffect(() => {
			if (!hasOpenedScenarioManager) {
				if (conversationOptionsButtonRef.current) {					
					conversationOptionsButtonRef.current.classList.add('pulse', 'pulse-round');
					conversationOptionsButtonRef.current.style.position = 'relative';
				}
				if (scenarioMenuItemRef.current) {
					scenarioMenuItemRef.current.classList.add('pulse');
					scenarioMenuItemRef.current.style.position = 'relative';
				}
			}
		}, [hasOpenedScenarioManager]); // Depend on the hasOpenedScenarioManager state

		// Function to setup menu toggle
		function setupMenuToggle(menuSelector, anchorSelector) {
			// Function to toggle the menu
			const toggleMenu = () => {
				const menuEl = document.body.querySelector(menuSelector);
				if (menuEl) {
					menuEl.open = !menuEl.open;
					// Check if the menu is now open or closed
					if (menuEl.open) {
						// If the menu is open, remove the pulse effect
						conversationOptionsButtonRef.current.classList.remove('pulse', 'pulse-round');
					}
				}
			};
			// Get the anchor element
			const anchorEl = document.body.querySelector(anchorSelector);
			// Add event listener to the anchor element
			if (anchorEl) {
				anchorEl.addEventListener('click', toggleMenu);
			}
			// Cleanup function to remove the event listener
			return () => {
				if (anchorEl) {
					anchorEl.removeEventListener('click', toggleMenu);
				}
			};
		}
		const submenu = languageMenuRef.current;
		if (submenu) {
			submenu.style.overflowY = 'auto';
			submenu.style.overflowX = 'hidden';
		}
		useEffect(() => {
			// Setup for first menu
			const cleanupFirstMenu = setupMenuToggle('#conversation-options-menu', '#conversation-options-menu-anchor');

			// Setup for second menu (if applicable)
			//const cleanupSecondMenu = setupMenuToggle('#languages-menu', '#options-submenu-anchor');

			// Cleanup function to remove event listeners
			return () => {
				cleanupFirstMenu();
				//cleanupSecondMenu();
			};
		}, []);

		const fetchPreferences = async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-preferences`, {
					method: 'GET',
					credentials: 'include'
				});
				const prefs = await response.json();

				setLanguage(prefs.language);
				setShowTransliteration(prefs.showTransliteration);
				setTheme(prefs.theme);
				localStorage.setItem('language', prefs.language);
				localStorage.setItem('setShowTransliteration', prefs.setShowTransliteration);
				localStorage.setItem('theme', prefs.theme);
			} catch (error) {
				console.error('Error fetching preferences:', error);
			}
		};
		const updatePreferences = async (newPreferences) => {
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}/api/update-preferences`, newPreferences, {
					withCredentials: true
				});
				await fetchPreferences(); // Fetch preferences to update UI
			} catch (error) {
				console.error('Error updating preferences:', error);
				console.error('Error details:', error.response);
			}
		};
		const handleLanguageChange = async (newLanguage) => {
			// Update the language preference
			setLanguage(newLanguage);
			localStorage.setItem('language', newLanguage);

			// List of languages that use non-Roman scripts
			const nonRomanLanguages = ['Japanese', 'Korean', 'Armenian', 'Russian', 'Persian', 'Traditional Chinese', 'Simplified Chinese'];

			// Check if the selected language is in the list of non-Roman languages
			if (nonRomanLanguages.includes(newLanguage)) {
				setShowTransliteration(true);
				localStorage.setItem('setShowTransliteration', true);
				await updatePreferences({ showTransliteration: true });
			}
			await updatePreferences({ language: newLanguage });
		};
		const handleTransliterationVisibilityChange = async (visibility) => {
			// Update the showTransliteration preference
			setShowTransliteration(visibility);
			localStorage.setItem('setShowTransliteration', visibility);
			await updatePreferences({ showTransliteration: visibility });
		};
		const handleThemeChange = async (newTheme) => {
			setTheme(newTheme);
			localStorage.setItem('theme', newTheme);
			// Update the theme preference
			await updatePreferences({ theme: newTheme });
		};

		const ws = useWebSocket();

		useEffect(() => {
			if (!ws) return;
			const onMessage = (event) => {
				const data = JSON.parse(event.data);
				// Update preferences if present in the incoming message
				if (data.preferences) {
					setLanguage(data.preferences.language);
					setShowTransliteration(data.preferences.showTransliteration);
					setTheme(data.preferences.theme);
				}
			};
			ws.addEventListener('message', onMessage);
			return () => {
				ws.removeEventListener('message', onMessage);
			};
		}, [ws]);

		return (
			<div className="conversation-options">
				<md-menu has-overflow id="conversation-options-menu" anchor="conversation-options-menu-anchor">
					<md-sub-menu>
						<md-menu-item slot="item">
							<md-icon slot="end">arrow_left</md-icon>
							<div slot="headline">{language}</div>
						</md-menu-item>
						<md-menu style={{ overflow: 'scroll' }} ref={languageMenuRef} slot="menu">
							{/* Language options */}
							<md-menu-item onClick={() => handleLanguageChange('French')}>
								<div slot="headline">French</div>
							</md-menu-item>
							<md-menu-item onClick={() => handleLanguageChange('Spanish')}>
								<div slot="headline">Spanish</div>
							</md-menu-item>
							<md-menu-item onClick={() => handleLanguageChange('German')}>
								<div slot="headline">German</div>
							</md-menu-item>
							<md-menu-item onClick={() => handleLanguageChange('Japanese')}>
								<div slot="headline">Japanese</div>
							</md-menu-item>
							<md-menu-item onClick={() => handleLanguageChange('Korean')}>
								<div slot="headline">Korean</div>
							</md-menu-item>
							<md-menu-item onClick={() => handleLanguageChange('Russian')}>
								<div slot="headline">Russian</div>
							</md-menu-item>
							<md-menu-item ref={languageMenuItemRef} onClick={openLanguageManager}>
								<div slot="headline" aria-label="Open a form dialog">Other</div>
							</md-menu-item>
							{/* ... other languages */}
						</md-menu>
					</md-sub-menu>
					<md-menu-item ref={scenarioMenuItemRef} onClick={openScenarioManager}>
						<div slot="headline" aria-label="Open a form dialog">Scenario Manager</div>
					</md-menu-item>
					<md-menu-item onClick={() => handleTransliterationVisibilityChange(!showTransliteration)}>
						<div slot="headline">
							{showTransliteration ? 'Hide Pronunciation' : 'Show Pronunciation'}
						</div>
					</md-menu-item>
					<md-menu-item onClick={handleResetConversation}>
						<div slot="headline">Reset Conversation</div>
					</md-menu-item>
					<md-menu-item onClick={() => handleThemeChange(theme === 'dark' ? 'light' : 'dark')}>
						<div slot="headline">Toggle Theme</div>
					</md-menu-item>
					{/*<md-menu-item onClick={}>
						<div slot="headline">Test Button</div>
					</md-menu-item>*/}
				</md-menu>
				<ScenarioManager
					ref={scenarioManagerRef}
				/>
				<LanguageManager
					handleLanguageChange={handleLanguageChange}
					ref={languageManagerRef}
				/>
			</div>
		);
	}

	return (
		<div className={theme}>
			<div className="App">
				<div className="app-container">
					<WebSocketProvider>
						<header className="app-header">
							<span style={{ position: "relative", borderRadius: '50%'}}>
								<md-icon-button
									id="conversation-options-menu-anchor"
									style={{ borderRadius: '50%', position: 'relative' }}
									ref={conversationOptionsButtonRef}
								>
									<md-icon>more_vert</md-icon>
								</md-icon-button>
								<ConversationOptionsMenu
									language={language}
									showTransliteration={showTransliteration}
									theme={theme}
									handleResetConversation={handleResetConversation}
								/>
							</span>
						</header>
						<ConversationArea
							showTransliteration={showTransliteration}
							language={language}
						/>
					</WebSocketProvider>
					<MessageInput />
				</div>
				{/*{<div className="conversation-history">
					<h2>Conversation History</h2>
					<pre>{JSON.stringify(conversationHistory, null, 2)}</pre>
					<h2>temporaryChunkStorage</h2>
					<pre>{JSON.stringify(temporaryChunkStorage, null, 2)}</pre>
				</div>}*/}
			</div>
		</div>
	);
}

export default App;