import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react';

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const wsRef = useRef(null); // Add this ref to track the WebSocket instance
    const reconnectAttempts = useRef(0);
    const handleReconnectRef = useRef(null);
    const heartbeatInterval = useRef(null);

    // Function to start the heartbeat
    const startHeartbeat = (wsInstance) => {
        clearInterval(heartbeatInterval.current); // Clear any existing interval
        heartbeatInterval.current = setInterval(() => {
            //console.log('Ping');
            wsInstance.send('ping');
        }, 30000);
    };

    const connectWebSocket = useCallback(() => {
        const newWs = new WebSocket(process.env.REACT_APP_WS_URL);

        setWs(newWs);
        wsRef.current = newWs; // Update the ref whenever a new WebSocket instance is created

        newWs.onopen = () => {
            console.log('WebSocket connection established');
            reconnectAttempts.current = 0;
            startHeartbeat(newWs);
        };

        newWs.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        newWs.onclose = (event) => {
            console.log('WebSocket connection closed', event);
            clearInterval(heartbeatInterval.current);
            if (!event.wasClean) {
                handleReconnectRef.current();
            }
        };
        newWs.onmessage = (event) => {
            if (event.data) {
                const messageObj = JSON.parse(event.data);
                if (messageObj.message === 'pong') {
                    //console.log('Pong received');
                } else {
                    // Handle other messages
                    //console.log('Received message:', messageObj.message);
                }
            }
        };
    }, []);
    const initializeCookieAndConnectRef = useRef(null);

    // No need to include handleReconnect in the dependency array of initializeCookieAndConnect
    const initializeCookieAndConnect = useCallback(() => {
        fetch(`${process.env.REACT_APP_API_URL}/api/set-cookie`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(response => {
                console.log('Cookie set, now connecting WebSocket');
                connectWebSocket();
            })
            .catch(error => {
                console.error('Error setting cookie:', error);
                // Use the ref to call handleReconnect indirectly
                if (handleReconnectRef.current) {
                    handleReconnectRef.current();
                }
            });
    }, [connectWebSocket]); // Removed handleReconnect from dependencies

    const handleReconnect = useCallback(() => {
        reconnectAttempts.current += 1;
        console.log("reconnectAttempts.current: ", reconnectAttempts.current);
        const backoffDelay = Math.min(1000 * (2 ** reconnectAttempts.current), 30000);
        setTimeout(() => {
            if (initializeCookieAndConnectRef.current) {
                initializeCookieAndConnectRef.current();
            }
        }, backoffDelay);
    }, []); // handleReconnect doesn't depend on initializeCookieAndConnect directly

    // Use useEffect to set up refs to the latest functions
    useEffect(() => {
        handleReconnectRef.current = handleReconnect;
        initializeCookieAndConnectRef.current = initializeCookieAndConnect;
    }, [handleReconnect, initializeCookieAndConnect]);


    useEffect(() => {
        console.log('Component mounted, setting up cookie and WebSocket');
        initializeCookieAndConnect();

        return () => {
            console.log('Component will unmount');
            // Use wsRef.current here to access the latest WebSocket instance
            if (wsRef.current) {
                wsRef.current.close();
            }
            clearInterval(heartbeatInterval.current);
        };
    }, [initializeCookieAndConnect]); // No need to include ws or wsRef in the dependencies

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    );
};
